import React , {useState} from "react";
import "../Styles/software.css";
import { Container, Button, Card, Modal, Row } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa6";

const Hardware = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  return (
    <div>
        <div>
      <div className="software-dispaly">
        <div className="software-title">
          <h2>Hardware Training</h2>
        </div>
        <div className="fixed-icon">
          <div style={{borderBottom:"1px solid white"}}>
          <a href="tel:7996748513" target="_new">
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 204.000000 192.000000"
              preserveAspectRatio="xMidYMid meet"
              width="43px"
              height="43px"
            >
              <g
                transform="translate(0.000000,192.000000) scale(0.100000,-0.100000)"
                fill="white"
                stroke="none"
              >
                <path
                  d="M982 1599 c3 -9 27 -17 65 -21 70 -8 180 -48 238 -88 127 -87 225
-268 225 -416 0 -24 5 -34 15 -34 34 0 9 148 -46 268 -21 48 -49 85 -107 141
-100 99 -201 148 -331 159 -52 4 -63 2 -59 -9z"
                />
                <path
                  d="M615 1511 c-22 -10 -55 -35 -74 -54 -57 -60 -53 -150 14 -339 43
-121 97 -210 176 -294 147 -158 301 -258 457 -299 79 -20 97 -19 150 6 56 27
102 96 102 151 0 33 -5 42 -42 68 -73 53 -184 110 -214 110 -18 0 -42 -12 -64
-31 -19 -16 -42 -34 -50 -39 -26 -14 -72 14 -164 100 -66 63 -96 101 -122 152
-19 37 -34 76 -34 86 0 10 15 31 34 45 52 42 62 76 41 139 -9 29 -32 86 -51
126 -46 95 -79 110 -159 73z"
                />
                <path
                  d="M960 1441 c0 -11 17 -16 82 -25 108 -14 258 -135 297 -240 11 -30 24
-83 28 -116 9 -67 26 -81 31 -24 4 48 -33 168 -68 218 -74 107 -201 186 -311
194 -39 3 -59 1 -59 -7z"
                />
                <path
                  d="M940 1277 c0 -10 15 -17 48 -22 121 -17 213 -110 231 -233 3 -25 12
-47 20 -50 11 -4 13 6 8 50 -14 138 -120 245 -262 265 -36 5 -45 3 -45 -10z"
                />
              </g>
            </svg>
          </a>
          </div>
         
          <div>
            <a href={"https://www.whatsapp.com/"} target="_new">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                viewBox="0,0,256,256"
                width="43px"
                height="43px"
              >
                <g transform="translate(51.2,51.2) scale(0.6,0.6)">
                  <g
                    fill="white "
                    fill-rule="nonzero"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray=""
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style={{ mixBlendMode: "normal" }}
                  >
                    <g transform="scale(5.12,5.12)">
                      <path d="M25,2c-12.682,0 -23,10.318 -23,23c0,3.96 1.023,7.854 2.963,11.29l-2.926,10.44c-0.096,0.343 -0.003,0.711 0.245,0.966c0.191,0.197 0.451,0.304 0.718,0.304c0.08,0 0.161,-0.01 0.24,-0.029l10.896,-2.699c3.327,1.786 7.074,2.728 10.864,2.728c12.682,0 23,-10.318 23,-23c0,-12.682 -10.318,-23 -23,-23zM36.57,33.116c-0.492,1.362 -2.852,2.605 -3.986,2.772c-1.018,0.149 -2.306,0.213 -3.72,-0.231c-0.857,-0.27 -1.957,-0.628 -3.366,-1.229c-5.923,-2.526 -9.791,-8.415 -10.087,-8.804c-0.295,-0.389 -2.411,-3.161 -2.411,-6.03c0,-2.869 1.525,-4.28 2.067,-4.864c0.542,-0.584 1.181,-0.73 1.575,-0.73c0.394,0 0.787,0.005 1.132,0.021c0.363,0.018 0.85,-0.137 1.329,1.001c0.492,1.168 1.673,4.037 1.819,4.33c0.148,0.292 0.246,0.633 0.05,1.022c-0.196,0.389 -0.294,0.632 -0.59,0.973c-0.296,0.341 -0.62,0.76 -0.886,1.022c-0.296,0.291 -0.603,0.606 -0.259,1.19c0.344,0.584 1.529,2.493 3.285,4.039c2.255,1.986 4.158,2.602 4.748,2.894c0.59,0.292 0.935,0.243 1.279,-0.146c0.344,-0.39 1.476,-1.703 1.869,-2.286c0.393,-0.583 0.787,-0.487 1.329,-0.292c0.542,0.194 3.445,1.604 4.035,1.896c0.59,0.292 0.984,0.438 1.132,0.681c0.148,0.242 0.148,1.41 -0.344,2.771z"></path>
                    </g>
                  </g>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>

      
      <div>
      <Container>
        <div className="fsdf mt-4 mb-4">
          <Card className="software-card">
            <Card.Header> Hardware Training</Card.Header>
            <div>
              <Card.Img src="../Assets/c2.png" className="software-img" />
            </div>
            <div>
                <Button variant="" className="parnets-btn" onClick={handleShow}>
                  Enquiry Now
                </Button>
              </div>
            <div className="overlay">
              <div className="text">Students will learn to build computers, troubleshoot hardware and software problems, networking, safety in the workplace, and basic electronics related to computer hardware.</div>
            </div>
          </Card>

          <Card className="software-card">
            <Card.Header>DELL Server</Card.Header>
            <div>
              <Card.Img src="../Assets/c14.png" className="software-img" />
            </div>
            <div>
                <Button variant="" className="parnets-btn" onClick={handleShow}>
                  Enquiry Now
                </Button>
              </div>
            <div className="overlay">
              <div className="text">Dell EMC PowerEdge XE servers are purpose-built for complex, harsh, and compute-intensive edge workloads that require high-performance and large amounts of storage.</div>
            </div>
          </Card>

          <Card className="software-card">
            <Card.Header>HPE Server</Card.Header>
            <div>
              <Card.Img src="../Assets/c5.png" className="software-img" />
            </div>
            <div>
                <Button variant="" className="parnets-btn" onClick={handleShow}>
                  Enquiry Now
                </Button>
              </div>
            <div className="overlay">
              <div className="text">ProLiant is a brand of server computers that was originally developed and marketed by Compaq, Hewlett-Packard, and currently marketed by Hewlett Packard Enterprise. After Compaq merged with Hewlett-Packard, HP retired its NetServer brand in favor of the ProLiant brand.</div>
            </div>
          </Card>
        </div>

        <div className="fsdf mt-4 mb-4">
        <Card className="software-card">
            <Card.Header>IBM Server </Card.Header>
            <div>
              <Card.Img src="../Assets/c4.png" className="software-img" />
            </div>
            <div>
                <Button variant="" className="parnets-btn" onClick={handleShow}>
                  Enquiry Now
                </Button>
              </div>
            <div className="overlay">
              <div className="text">IBM® Power® is a family of servers that are based on IBM Power processors and are capable of running IBM AIX®, IBM i and Linux®. Respond faster to business demands, protect your data from core to cloud, and streamline insights and automation.</div>
            </div>
          </Card>

          <Card className="software-card">
            <Card.Header>SAN / NAS EMC HPE  </Card.Header>
            <div>
              <Card.Img src="../Assets/c13.png" className="software-img" />
            </div>
            <div>
                <Button variant="" className="parnets-btn" onClick={handleShow}>
                  Enquiry Now
                </Button>
              </div>
            <div className="overlay">
              <div className="text">EMC  &  HPE storage refers to the various storage products, systems and services being offered by EMC Corporation, which include disk, flash and hybrid storage systems and arrays.</div>
            </div>
          </Card>

          <Card className="software-card">
            <Card.Header>Networking</Card.Header>
            <div>
              <Card.Img src="../Assets/c11.png" className="software-img" />
            </div>
            <div>
                <Button variant="" className="parnets-btn" onClick={handleShow}>
                  Enquiry Now
                </Button>
              </div>
            <div className="overlay">
              <div className="text">A computer network is a set of computers sharing resources located on or provided by network nodes. Computers use common communication protocols over digital interconnections to communicate with each other.</div>
            </div>
          </Card>
        </div>
      </Container>
      </div>
    </div>

     {/* Enquiry Modal */}
     <Modal show={show} onHide={handleClose} style={{ zIndex: "9999999" }}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#083a87" }}>
            Course Enquiry Form
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="col-lg-12 mb-2">
              <label className="fw-bold">Name :</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Name"
              />
            </div>
            <div className="col-lg-12 mb-3">
              <label className="fw-bold">Number :</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your number"
              />
            </div>
            <div className="col-lg-12 mb-3">
              <label className="fw-bold">Email :</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Email ID"
              />
            </div>
            {/* <div className="col-lg-12 mb-3">
              <label className="fw-bold">Looking for :</label>
              <select className="form-control">
                <option>Select Course Type</option>
                <option>Online Courses</option>
                <option>Offline Courses</option>
              </select>
            </div> */}
            <div className="col-lg-12 mb-3">
              <label className="fw-bold">Message :</label>
              <textarea
                className="form-control"
                placeholder="Leave a message here"
                id="floatingTextarea"
              ></textarea>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            style={{ backgroundColor: "#13df78f7", color: "black" , border:"1px solid #044ef5"}}
            onClick={handleClose}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Hardware