
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from './Components/Header';
import Home from './Components/Home';
import Footer from './Components/Footer';
import Software from './Components/Software';
import Hardware from './Components/Hardware';
import Contactus from './Components/Contactus';
import Aboutus from './Components/Aboutus';
import Career from './Components/Career';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            exact
            element={
              <>
                <Header />
                <Home />
                <Footer />


              </>
            }
          />

          <Route
            path="/aboutus"
            exact
            element={
              <>
                <Header />
                <Aboutus />
                <Footer />


              </>
            }
          />

          <Route
            path="/career"
            exact
            element={
              <>
                <Header />
                <Career />
                <Footer />


              </>
            }
          />

          <Route
            path="/software"
            exact
            element={
              <>
                <Header />
                <Software />
                <Footer />


              </>
            }
          />

          <Route
            path="/hardware"
            exact
            element={
              <>
                <Header />
                <Hardware />
                <Footer />


              </>
            }
          />
          <Route
            path="/contactus"
            exact
            element={
              <>
                <Header />
                <Contactus />
                <Footer />


              </>
            }
          />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
