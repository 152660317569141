import React from "react";
import "../Styles/footer.css";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";


const Footer = () => {
  return (
    <div>
      <div className="footer-container">
        <Container className="p-2">
          <Row>
          <div className="col-md-2">
                {/* <img src="../Assets/logo.png" alt="logo" className="navbar-logo" /> */}
                <div className="footer-logo-display">
              <a href="/" className="tail-text">
                <img
                  src="../Assets/logo.jpeg"
                  alt="logo"
                  className="footer-nav-logo"
                />
                <span className="logo-name">TECHAR²</span>
              </a>
            </div>
                <div className="footer-description">
                    {/* <p>NEED ASSISTANCE? <br />WE’RE HERE TO HELP. </p> */}
                    {/* <p><b>TechAR2</b></p> */}
                    <p>Block A, Yeswanthpura,<br />
                        Bangalore - 560022 <br /> Karnataka India.</p>
                </div>
            </div>
            <div className="col-md-2">
                <h3>Quick Links</h3>

                <ul>
                    <li>
                        <span><img src="../Assets/icon.png" alt="logo" className="icon" /></span>
                        <a href="/"> Home</a>

                    </li>
                    <li>
                        <span><img src="../Assets/icon.png" alt="logo" className="icon" /></span>
                        <a href="/aboutus"> About Us</a>

                    </li>
                    <li>
                        <span><img src="../Assets/icon.png" alt="logo" className="icon" /></span>
                        <a href="/career"> Careers</a>
                    </li>
                    <li>
                        <span><img src="../Assets/icon.png"alt="logo" className="icon" /></span>
                        <a href="/contactus"> Contact us</a>

                    </li>

                    
                </ul>
            </div>

            <div className="col-md-2">
                <h3>Our Services</h3>
                <ul>
                    <li>
                        <span><img src="../Assets/icon.png" alt="logo" className="icon" /></span>
                        <a href="#"> Software Training</a>

                    </li>
                    <li>
                        <span><img src="../Assets/icon.png" alt="logo" className="icon" /></span>
                        <a href="#"> Hardware Training</a>
                    </li>
                    <li>
                        <span><img src="../Assets/icon.png" alt="logo" className="icon" /></span>
                        <a href="#"> Internship</a>
                    </li>
                    <li>
                        <span><img src="../Assets/icon.png" alt="logo" className="icon" /></span>
                        <a href="#"> Job Interviews</a>
                    </li>
                   
                </ul>
            </div>

            <div className="col-md-2">
                <h3>Contact Us</h3>
                <ul>
                    <li>
                        <span><img src="../Assets/email.png" alt="logo" className="icon-contact" /></span>
                        <a href="#">contactus@techar2.com </a>
                    </li>
                    <li>
                        <span><img src="../Assets/phone.png"alt="logo" className="icon-contact" /></span>
                        <a href="#"> +91 9876543210</a>
                    </li>
                    <div className="navbar-social-icon mt-2">
                        <a
                            traget="_blank"
                            href="https://www.facebook.com/"
                        >
                            <img src="../Assets/fb.png" alt="logo1" className="footer-social-icon" />
                        </a>
                        <a
                            traget="_blank"
                            href="https://www.twitter.com/"
                        >
                            <img src="../Assets/twitter.png" alt="logo1" className="footer-social-icon" />
                        </a>
                        <a
                            traget="_blank"
                            href="https://www.instagram.com/"
                        >
                            <img src="../Assets/insta.png" alt="logo1" className="footer-social-icon" />
                        </a>
                        <a
                            traget="_blank"
                            href="https://www.linkedin.com/"
                        >
                            <img src="../Assets/linkedin.png" alt="logo1" className="footer-social-icon" />
                        </a>
                        {/* <a
                            traget="_blank"
                            href="https://www.pinterest.com/"
                        >
                            <img src="../Assets/pinrest.png" alt="logo1" className="footer-social-icon" />
                        </a> */}
                    </div>
                    
                 
                </ul>
            </div>
          </Row>

          {/* <Row>
            <div className="end-title">
                <p>© 2024 TechAR2. All Right Reserved.</p>
            </div>
          </Row> */}
        </Container>
      </div>
    </div>
  );
};

export default Footer;
