import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";

import "../Styles/header.css";
import { Button, Row, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";

const Header = () => {
  const navigate = useNavigate();
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    setShow1(true);
  };
  return (
    <div
      className="navbar-header"
      style={{
        position: "sticky",
        top: "0px",
        zIndex: "999",
        backgroundColor: "white",
      }}
    >
      {["xl"].map((expand) => (
        <Navbar key={expand} expand={expand} className="navbar">
          <Container fluid>
            <div className="logo-display">
              <a href="/" className="tail-text">
                <img
                  src="../Assets/logo.jpeg"
                  alt="logo"
                  className="nav-logo"
                />
                <span className="logo-name">TECHAR²</span>
              </a>
            </div>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton></Offcanvas.Header>
              <Offcanvas.Body>
                <Nav
                  className="justify-content-end flex-grow-1 pe-3"
                  style={{ alignItems: "center" }}
                >
                  <Nav.Link href="/" className="tail-text">
                    HOME
                  </Nav.Link>
                  <Nav.Link href="/aboutus" className="tail-text">
                    ABOUT US
                  </Nav.Link>
                  <Nav.Link href="/career" className="tail-text">
                    CAREERS
                  </Nav.Link>

                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      id="dropdown-basic"
                      className="tail-text"
                    >
                      SERVICES
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="/software">
                        Software Training
                      </Dropdown.Item>
                      <Dropdown.Item href="/hardware">
                        Hardware Training
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* <Nav.Link href="/software" className="tail-text" >
                    SOFTWARE TRAINING
                  </Nav.Link>
                  <Nav.Link href="/hardware" className="tail-text" >
                    HARDWARE TRAINING
                  </Nav.Link> */}
                  <Nav.Link href="/contactus" className="tail-text">
                    CONTACT US
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
      {/* <hr style={{margin:"0"}}></hr> */}

      {/* Enquiry Modal */}

      <Modal show={show1} onHide={handleClose1} style={{ zIndex: "99999999" }}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#083a87" }}>
            Partner Request
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="col-lg-12 mb-2">
              <label className="fw-bold">Name :</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Name"
              />
            </div>
            <div className="col-lg-12 mb-3">
              <label className="fw-bold">Number :</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your number"
              />
            </div>
            <div className="col-lg-12 mb-3">
              <label className="fw-bold">Email :</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Email ID"
              />
            </div>
            <div className="col-lg-12 mb-3">
              <label className="fw-bold">Looking for :</label>
              <select className="form-control">
                <option>Select Job Type</option>
                <option>Full Time</option>
                <option>Part Time</option>
              </select>
            </div>
            <div className="col-lg-12 mb-3">
              <label className="fw-bold">Message :</label>
              <textarea
                className="form-control"
                placeholder="Tell Us Why You want to be a Pro.."
                id="floatingTextarea"
              ></textarea>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            style={{
              background: "#080874 ",
              color: "white",
            }}
            onClick={handleClose1}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Header;
