import React, { useState, useEffect } from "react";
import "../Styles/home.css";
import { Container, Button, Card, Modal, Row } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Carousel from "react-bootstrap/Carousel";
import Aos from "aos";
import { FaLaptopCode } from "react-icons/fa";
import { SiOpensourcehardware } from "react-icons/si";
import { FaStar } from "react-icons/fa";
import { FaQuoteLeft } from "react-icons/fa6";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MagicSliderDots from "react-magic-slider-dots";
import "react-magic-slider-dots/dist/magic-dots.css";
import { GrStar } from "react-icons/gr";

const Home = () => {
  useEffect(() => {
    Aos.init();
    window.scroll(0, 0);
  });

  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const options1 = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => {
      return <MagicSliderDots dots={dots} numDotsToShow={2} dotWidth={30} />;
    },
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };


  return (
    <>
      {/* Banner /slider  */}

      <div className="home-slider">
        <div className="-cotainerslider">
          <Carousel>
            <Carousel.Item>
              <img
                src="../Assets/Slider1.png"
                alt="df"
                text="First slide"
                className="slider-img"
              />
              <Carousel.Caption>
                <div
                  className="col-md-11 "
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <div className="text-display">
                    <h1>Learn New Skills In Online with Top Courses </h1>
                    <div className="learmore-btn-display mt-3">
                      <a href="#abt">
                        <Button variant="" className="parnets-btn">
                          Learn More
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                src="../Assets/Slider2.png"
                alt="df"
                text="Second slide"
                className="slider-img"
              />
              <Carousel.Caption>
                <div
                  className="col-md-11 "
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <div className="text-display">
                    <h1>Change Your Life We Provide Training </h1>
                    <div className="learmore-btn-display mt-3">
                      <a href="#abt">
                        <Button variant="" className="parnets-btn">
                          Learn More
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                src="../Assets/Slider3.png"
                alt="df"
                text="Third slide"
                className="slider-img"
              />
              <Carousel.Caption>
                <div
                  className="col-md-11 "
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  <div className="text-display">
                    <h1>We Build Your Dream Jobs at TECHAR² </h1>
                    <div className="learmore-btn-display mt-3">
                      <a href="#abt">
                        <Button variant="" className="parnets-btn">
                          Learn More
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>

        <div className="fixed-icon">
          <div style={{ borderBottom: "1px solid white" }}>
            <a href="tel:1234567890" target="_new">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 204.000000 192.000000"
                preserveAspectRatio="xMidYMid meet"
                width="43px"
                height="43px"
              >
                <g
                  transform="translate(0.000000,192.000000) scale(0.100000,-0.100000)"
                  fill="white"
                  stroke="none"
                >
                  <path
                    d="M982 1599 c3 -9 27 -17 65 -21 70 -8 180 -48 238 -88 127 -87 225
-268 225 -416 0 -24 5 -34 15 -34 34 0 9 148 -46 268 -21 48 -49 85 -107 141
-100 99 -201 148 -331 159 -52 4 -63 2 -59 -9z"
                  />
                  <path
                    d="M615 1511 c-22 -10 -55 -35 -74 -54 -57 -60 -53 -150 14 -339 43
-121 97 -210 176 -294 147 -158 301 -258 457 -299 79 -20 97 -19 150 6 56 27
102 96 102 151 0 33 -5 42 -42 68 -73 53 -184 110 -214 110 -18 0 -42 -12 -64
-31 -19 -16 -42 -34 -50 -39 -26 -14 -72 14 -164 100 -66 63 -96 101 -122 152
-19 37 -34 76 -34 86 0 10 15 31 34 45 52 42 62 76 41 139 -9 29 -32 86 -51
126 -46 95 -79 110 -159 73z"
                  />
                  <path
                    d="M960 1441 c0 -11 17 -16 82 -25 108 -14 258 -135 297 -240 11 -30 24
-83 28 -116 9 -67 26 -81 31 -24 4 48 -33 168 -68 218 -74 107 -201 186 -311
194 -39 3 -59 1 -59 -7z"
                  />
                  <path
                    d="M940 1277 c0 -10 15 -17 48 -22 121 -17 213 -110 231 -233 3 -25 12
-47 20 -50 11 -4 13 6 8 50 -14 138 -120 245 -262 265 -36 5 -45 3 -45 -10z"
                  />
                </g>
              </svg>
            </a>
          </div>

          <div>
            <a href={"https://www.whatsapp.com/"} target="_new">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                viewBox="0,0,256,256"
                width="43px"
                height="43px"
              >
                <g transform="translate(51.2,51.2) scale(0.6,0.6)">
                  <g
                    fill="white "
                    fill-rule="nonzero"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray=""
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style={{ mixBlendMode: "normal" }}
                  >
                    <g transform="scale(5.12,5.12)">
                      <path d="M25,2c-12.682,0 -23,10.318 -23,23c0,3.96 1.023,7.854 2.963,11.29l-2.926,10.44c-0.096,0.343 -0.003,0.711 0.245,0.966c0.191,0.197 0.451,0.304 0.718,0.304c0.08,0 0.161,-0.01 0.24,-0.029l10.896,-2.699c3.327,1.786 7.074,2.728 10.864,2.728c12.682,0 23,-10.318 23,-23c0,-12.682 -10.318,-23 -23,-23zM36.57,33.116c-0.492,1.362 -2.852,2.605 -3.986,2.772c-1.018,0.149 -2.306,0.213 -3.72,-0.231c-0.857,-0.27 -1.957,-0.628 -3.366,-1.229c-5.923,-2.526 -9.791,-8.415 -10.087,-8.804c-0.295,-0.389 -2.411,-3.161 -2.411,-6.03c0,-2.869 1.525,-4.28 2.067,-4.864c0.542,-0.584 1.181,-0.73 1.575,-0.73c0.394,0 0.787,0.005 1.132,0.021c0.363,0.018 0.85,-0.137 1.329,1.001c0.492,1.168 1.673,4.037 1.819,4.33c0.148,0.292 0.246,0.633 0.05,1.022c-0.196,0.389 -0.294,0.632 -0.59,0.973c-0.296,0.341 -0.62,0.76 -0.886,1.022c-0.296,0.291 -0.603,0.606 -0.259,1.19c0.344,0.584 1.529,2.493 3.285,4.039c2.255,1.986 4.158,2.602 4.748,2.894c0.59,0.292 0.935,0.243 1.279,-0.146c0.344,-0.39 1.476,-1.703 1.869,-2.286c0.393,-0.583 0.787,-0.487 1.329,-0.292c0.542,0.194 3.445,1.604 4.035,1.896c0.59,0.292 0.984,0.438 1.132,0.681c0.148,0.242 0.148,1.41 -0.344,2.771z"></path>
                    </g>
                  </g>
                </g>
              </svg>
            </a>
          </div>
        </div>
        
      </div>

      {/* About us  */}

      <div
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false"
      >
        <div className="about-display mt-2 mb-4" id="abt">
          <h3 className="about-title">ABOUT US</h3>
          <Container>
            <p className="tagline mb-4">
              Techar2 Technologies is a technology driven organization that is committed to providing solutions to industries and enterprises. With a focus on utilizing future-ready technologies. Techar2 Technologies is a technology driven organization that is committed to providing solutions to industries and enterprises. With a focus on utilizing future-ready technologies.
            </p>
            <div>
              <div className="about-display-container">
                <div className="about-img-container">
                 
                    <img
                      src="../Assets/aboutimg.png"
                      alt=""
                      className="about-img"
                    />
                </div>
                <div className="about-img-container">
                
                  <div className="about-content">
                    <div>
                      <div className="about-title-display">
                        <span className="about-icon">
                          {" "}
                          <FaLaptopCode style={{ color: "white" }} />
                        </span>
                        <h3>Software Training</h3>
                      </div>
                      <p>
                        Software Training is basically concern with teaching,
                        enhancing or developing in any technical skills and
                        practical knowledge that relate to specific or
                        perticular software which are useful to improve
                        technical competencies in the Information and Technology
                        field.
                      </p>
                    </div>
                    <div>
                      <div className="about-title-display">
                        <span className="about-icon">
                          {" "}
                          <SiOpensourcehardware style={{ color: "white" }} />
                        </span>
                        <h3>Hardware Training</h3>
                      </div>
                      <p>
                        Hardware courses understanding different parts of computer such as monitor, mouse, graphic card, keyboard, hard disk drive, motherboard, RAM, ROM, et al. Networking courses – understanding the exchange of data between computer systems either through a cable or wireless technology.
                      </p>
                    </div>
                  </div>
                  <div>
                    <a href="/aboutus">
                      <Button variant="" className="parnets-btn">View More</Button>
                    </a>
                  </div>

                </div>
              </div>
              </div>
          </Container>
        </div>
      </div>

      {/* Top Categories of our courses  */}

      <div
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false"
      >
        <div className="about-display mt-2 mb-4">
          <h3 className="about-title">OUR TOP COURSE CATEGORIES</h3>
          <Container>
            <h4><b>Get Trained in IT Industry </b></h4>
            <p className="tagline mb-2">
              Training From experts having 20 years plus working experienced in
              big IT Multinational companies (Infosys, HCL, Wipro, TCS, IBM,
              Hewllet Packard Enterprise).
              {/* <br /> */}
              Experienced in providing Industry ready corporate training for JOB
              seekers and college/schools students.
            </p>
            <OwlCarousel
              className="owl-theme"
              loop
              margin={5}
              nav
              {...options1}
            >
              <div class="item">
                <div class="card" style={{ width: "auto", height: "230px" }}>
                  <div class="image-box">
                    <img
                      src="../Assets/c1.png"
                      alt=""
                      className="homepage-service-img"
                    />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">SOFTWARE TRAINING</h5>
                  </div>
                </div>
              </div>

              <div class="item">
                <div class="card" style={{ width: "auto", height: "230px" }}>
                  <div class="image-box">
                    <img
                      src="../Assets/c2.png"
                      alt=""
                      className="homepage-service-img"
                    />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">HARDWARE TRAINING </h5>
                  </div>
                </div>
              </div>

              <div class="item">
                <div class="card" style={{ width: "auto", height: "230px" }}>
                  <div class="image-box">
                    <img
                      src="../Assets/c8.png"
                      alt=""
                      className="homepage-service-img"
                    />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">CLOUD COMPUTING</h5>
                  </div>
                </div>
              </div>

              <div class="item">
                <div class="card" style={{ width: "auto", height: "230px" }}>
                  <div class="image-box">
                    <img
                      src="../Assets/c9.png"
                      alt=""
                      className="homepage-service-img"
                    />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">AUTOMATION</h5>
                  </div>
                </div>
              </div>

              <div class="item">
                <div class="card" style={{ width: "auto", height: "230px" }}>
                  <div class="image-box">
                    <img
                      src="../Assets/c10.png"
                      alt=""
                      className="homepage-service-img"
                    />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">AWS DEVELOPMENT</h5>
                  </div>
                </div>
              </div>

              <div class="item">
                <div class="card" style={{ width: "auto", height: "230px" }}>
                  <div class="image-box">
                    <img
                      src="../Assets/c11.png"
                      alt=""
                      className="homepage-service-img"
                    />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">NETWORKING</h5>
                  </div>
                </div>
              </div>

              <div class="item">
                <div class="card" style={{ width: "auto", height: "230px" }}>
                  <div class="image-box">
                    <img
                      src="../Assets/c12.png"
                      alt=""
                      className="homepage-service-img"
                    />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">(GCP) GOOGLE CLOUD</h5>
                  </div>
                </div>
              </div>

              <div class="item">
                <div class="card" style={{ width: "auto", height: "230px" }}>
                  <div class="image-box">
                    <img
                      src="../Assets/c13.png"
                      alt=""
                      className="homepage-service-img"
                    />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">STORAGE SAN /NAS </h5>
                  </div>
                </div>
              </div>

              <div class="item">
                <div class="card" style={{ width: "auto", height: "230px" }}>
                  <div class="image-box">
                    <img
                      src="../Assets/c14.png"
                      alt=""
                      className="homepage-service-img"
                    />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title"> HP, DELL SERVER</h5>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </Container>
        </div>
      </div>

      {/* our most popular courses */}
      <div
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false"
      >
        <div className="about-display mt-2 mb-4">
          <h3 className="about-title">OUR MOST POPULAR COURSES</h3>
          <Container >
            <div className="about-display-container mt-3">
              <div id="cards_landscape_wrap-2">
                <div className="service-cards">
                  <div class="card-flyer mb-2">
                    <div class="text-box">
                      <div class="image-box">
                        <img
                          src="../Assets/c1.png"
                          alt=""
                          className="homepage-service-img"
                        />
                      </div>

                      <div class="text-container mt-2 mb-2">
                        <h6>Software Training</h6>
                        <div className="rating-display">
                          <p>4.9</p>{" "}
                          <span>
                            <FaStar
                              style={{ color: "orange", fontSize: "14px" }}
                            />
                            <FaStar
                              style={{ color: "orange", fontSize: "14px" }}
                            />
                            <FaStar
                              style={{ color: "orange", fontSize: "14px" }}
                            />
                            <FaStar
                              style={{ color: "orange", fontSize: "14px" }}
                            />
                            <FaStar
                              style={{ color: "orange", fontSize: "14px" }}
                            />
                          </span>{" "}
                          <p>(1999)</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-flyer mb-2">
                    <div class="text-box">
                      <div class="image-box">
                        <img
                          src="../Assets/c2.png"
                          alt=""
                          className="homepage-service-img"
                        />
                      </div>

                      <div class="text-container mt-2 mb-2">
                        <h6>Hardware Training</h6>
                        <div className="rating-display">
                          <p>4.3</p>{" "}
                          <span>
                            <FaStar
                              style={{ color: "orange", fontSize: "14px" }}
                            />
                            <FaStar
                              style={{ color: "orange", fontSize: "14px" }}
                            />
                            <FaStar
                              style={{ color: "orange", fontSize: "14px" }}
                            />
                            <FaStar
                              style={{ color: "orange", fontSize: "14px" }}
                            />
                            <FaStar
                              style={{ color: "orange", fontSize: "14px" }}
                            />
                          </span>{" "}
                          <p>(1987)</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-flyer mb-2">
                    <div class="text-box">
                      <div class="image-box">
                        <img
                          src="../Assets/c10.png"
                          alt=""
                          className="homepage-service-img"
                        />
                      </div>

                      <div class="text-container mt-2 mb-2">
                        <h6>AWS / GCP </h6>
                        <div className="rating-display">
                          <p>4.2</p>{" "}
                          <span>
                            <FaStar
                              style={{ color: "orange", fontSize: "14px" }}
                            />
                            <FaStar
                              style={{ color: "orange", fontSize: "14px" }}
                            />
                            <FaStar
                              style={{ color: "orange", fontSize: "14px" }}
                            />
                            <FaStar
                              style={{ color: "orange", fontSize: "14px" }}
                            />
                            <FaStar
                              style={{ color: "orange", fontSize: "14px" }}
                            />
                          </span>{" "}
                          <p>(1991)</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-flyer mb-2">
                    <div class="text-box">
                      <div class="image-box">
                        <img
                          src="../Assets/c9.png"
                          alt=""
                          className="homepage-service-img"
                        />
                      </div>

                      <div class="text-container mt-2 mb-2">
                        <h6>Automation</h6>
                        <div className="rating-display">
                          <p>4.1</p>{" "}
                          <span>
                            <FaStar
                              style={{ color: "orange", fontSize: "14px" }}
                            />
                            <FaStar
                              style={{ color: "orange", fontSize: "14px" }}
                            />
                            <FaStar
                              style={{ color: "orange", fontSize: "14px" }}
                            />
                            <FaStar
                              style={{ color: "orange", fontSize: "14px" }}
                            />
                            <FaStar
                              style={{ color: "orange", fontSize: "14px" }}
                            />
                          </span>{" "}
                          <p>(1979)</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>

      {/* What people say  */}

      <div
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false"
      >
        <div className="about-display mt-3 mb-2 ">
          <h3 className="about-title">WHAT PEOPLE SAY</h3>
          <div className="about-container">
            <div className="container mx-auto">
              <div className="cate-0">
               
                <OwlCarousel
                  className="owl-theme"
                  loop
                  margin={5}
                  nav
                  {...options}
                >
                  <div class="item">
                    <Card className="text-center" style={{ margin: "0" }}>
                      <Card.Header>
                        <FaQuoteLeft
                          style={{ color: "#10398c", margin: "10px 0px" }}
                        />
                      </Card.Header>
                      <Card.Body>
                        <Card.Text>
                        The experience of learning in this institute is really good. I've joined the AWS course Doing well. Thanks to the management to provide wonderful courses . I am new to this field but the way they teach was very good. {" "}
                        </Card.Text>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src="../Assets/R1.png"
                              alt=""
                              className="review-img"
                            />
                          </div>
                          <div className="reviewer-name">
                            <p>John </p>
                            <p>Software Developer</p>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  <div class="item">
                    <Card className="text-center" style={{ margin: "0" }}>
                      <Card.Header>
                        <FaQuoteLeft
                          style={{ color: "#10398c", margin: "10px 0px" }}
                        />
                      </Card.Header>
                      <Card.Body>
                        <Card.Text>
                        Very good institute with good teaching faculty Its the best institute in the bangalore for software courses they explained from basics to Advanced level courses and everything they are explained very well.{" "}
                        </Card.Text>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src="../Assets/R2.png"
                              alt=""
                              className="review-img"
                            />
                          </div>
                          <div className="reviewer-name">
                            <p>Duran G</p>
                            <p>Android Developer</p>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  <div class="item">
                    <Card className="text-center" style={{ margin: "0" }}>
                      <Card.Header>
                        <FaQuoteLeft
                          style={{ color: "#10398c", margin: "10px 0px" }}
                        />
                      </Card.Header>
                      <Card.Body>
                        <Card.Text>
                        I have learnt many number of courses in this institute. It helped me in developing my base in computers these coursed helped me a lot and this institute provides with good infrastructure and a friendly environment.{" "}
                        </Card.Text>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src="../Assets/R3.png"
                              alt=""
                              className="review-img"
                            />
                          </div>
                          <div className="reviewer-name">
                            <p>Richard KP</p>
                            <p>Software Developer</p>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  <div class="item">
                    <Card className="text-center">
                      <Card.Header>
                        <FaQuoteLeft
                          style={{ color: "#10398c", margin: "10px 0px" }}
                        />
                      </Card.Header>
                      <Card.Body>
                        <Card.Text>
                        Very Good institute to learn software courses, I paid money for the institute to learn courses there is worthy I am still planning to attend more courses to learn more about software technologies.{" "}
                        </Card.Text>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src="../Assets/R4.png"
                              alt=""
                              className="review-img"
                            />
                          </div>
                          <div className="reviewer-name">
                            <p>Roman JP</p>
                            <p>Backend Developer</p>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  <div class="item">
                    <Card className="text-center">
                      <Card.Header>
                        <FaQuoteLeft
                          style={{ color: "#10398c", margin: "10px 0px" }}
                        />
                      </Card.Header>
                      <Card.Body>
                        <Card.Text>
                        I have attended Cloud Computing training its owesome. If you want to learn from scratch to advance level then blindly go to this courses. it is very useful to all software developers they can easly understand. {" "}
                        </Card.Text>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src="../Assets/R5.png"
                              alt=""
                              className="review-img"
                            />
                          </div>
                          <div className="reviewer-name">
                            <p>Roberto Duran</p>
                            <p>Android Developer</p>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  <div class="item">
                    <Card className="text-center" style={{ margin: "0" }}>
                      <Card.Header>
                        <FaQuoteLeft
                          style={{ color: "#10398c", margin: "10px 0px" }}
                        />
                      </Card.Header>
                      <Card.Body>
                        <Card.Text>
                        By this course I got more knowledge about AWS, Cloud computing and Automation. I got more information about how to face the interviews and in this all trainers and faculty are good. I learnt more in this course.{" "}
                        </Card.Text>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src="../Assets/R6.png"
                              alt=""
                              className="review-img"
                            />
                          </div>
                          <div className="reviewer-name">
                            <p>Jancy R</p>
                            <p>Web Developer</p>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Our Statistics  */}

      <div
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false"
      >
        <div className="about-display mt-3 mb-4">
          <h3 className="about-title">OUR STATISTICS SO FAR</h3>
          <Container>
            <p className="tagline mb-2">
              Experienced in providing Industry ready corporate training for JOB
              seekers and college/schools students. Training from experts having
              20 years plus working experienced in big IT Multinational companies
              (Infosys, HCL, Wipro, TCS, IBM, Hewllet Packard Enterprise).
            </p>

            <div className="about-display-container1">
              <div className="numbers">
                <div className="sdfsd">
                  <p>1000 +</p>
                  <p>Students</p>
                </div>
              </div>
              <div className="numbers">
                <div className="sdfsd">
                  <p>2000 +</p>
                  <p>Courses</p>
                </div>
              </div>
              <div className="numbers">
                <div className="sdfsd">
                  <p>1500 +</p>
                  <p>Reviews</p>
                </div>
              </div>
              <div className="numbers">
                <div className="sdfsd">
                  <p>2500 +</p>
                  <p>Community</p>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>

      {/* Learning Plan mode  */}
      
      <div
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false"
      >
        <div className="about-display mt-2 mb-4">
          <h3 className="about-title">LEARNING COURSES</h3>
          <p className="tagline mb-4  text-center" style={{ padding: "10px" }}>
            How to Choose the Best One for Your Budget. Learn about the
            different student loan repayment plans. Determine how much you can
            pay.
          </p>

          <div className="about-display-container1 gap-5">
            <Card className="priceplan-card">
              <Card.Img
                variant="top"
                src="../Assets/T1.png"
                className="course-plan-img"
              />
              <Card.Body>
                <Card.Title>SOFTWARE TRAINING</Card.Title>
                <Card.Text>
                  <p>
                    (Cloud Computing, Automation, AWS, GCP, Open Stack / Open
                    Shift)
                  </p>
                  <p>$ 5999/-</p>
                </Card.Text>
                <Button variant="" className="parnets-btn" onClick={handleShow}>
                  Enquiry Now
                </Button>
              </Card.Body>
            </Card>

            <Card className="priceplan-card">
              <Card.Img
                variant="top"
                src="../Assets/T2.png"
                className="course-plan-img"
              />
              <Card.Body>
                <Card.Title>HARDWARE TRAINING</Card.Title>
                <Card.Text>
                  <p>(Dell, HPE, IBM Servers, Storage EMC, HPE, Networking)</p>
                  <p>$ 5999/-</p>
                </Card.Text>
                <Button variant="" className="parnets-btn" onClick={handleShow}>
                  Enquiry Now
                </Button>
              </Card.Body>
            </Card>

            {/* <Card className="priceplan-card">
              <Card.Img
                variant="top"
                src="../Assets/cr3.png"
                className="course-plan-img"
              />
              <Card.Body>
                <Card.Title>FULLSTACK DEVELOPMENT</Card.Title>
                <Card.Text>
                  <p>(React.js, Node js, Express Js, & Mongo DB)</p>
                  <p>$ 5999/-</p>
                </Card.Text>
                <Button variant="" className="parnets-btn" onClick={handleShow}>
                  PURCHASE
                </Button>
              </Card.Body>
            </Card> */}
          </div>
        </div>
      </div>

      {/* Enquiry Modal */}
      <Modal show={show} onHide={handleClose} style={{ zIndex: "9999999" }}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#083a87" }}>
            Course Enquiry Form
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="col-lg-12 mb-2">
              <label className="fw-bold">Name :</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Name"
              />
            </div>
            <div className="col-lg-12 mb-3">
              <label className="fw-bold">Number :</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your number"
              />
            </div>
            <div className="col-lg-12 mb-3">
              <label className="fw-bold">Email :</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Email ID"
              />
            </div>
            {/* <div className="col-lg-12 mb-3">
              <label className="fw-bold">Looking for :</label>
              <select className="form-control">
                <option>Select Course Type</option>
                <option>Online Courses</option>
                <option>Offline Courses</option>
              </select>
            </div> */}
            <div className="col-lg-12 mb-3">
              <label className="fw-bold">Message :</label>
              <textarea
                className="form-control"
                placeholder="Leave a message here"
                id="floatingTextarea"
              ></textarea>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            style={{ backgroundColor: "#083a87", color: "white" }}
            onClick={handleClose}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Home;
